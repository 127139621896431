/* eslint-disable react/display-name */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled, { css } from 'styled-components';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Icon } from 'src/components/atoms/Icon';
import { McolGrid, wrapLast, frame } from 'src/system';

const mcolList = css`
  --row-gap: 6px;
  max-width: 500px;
  @media (min-width: 840px) {
    --row-gap: 12px;
    --multi-columns: 2;
    max-width: 800px;
  }
  margin: 0 auto;
  padding: 0;
`;

const mcolListItem = css`
  margin: 0;
  padding: 0;
`;

const section = css`
  margin-top: 60px;
  margin-bottom: 60px;
`;
const sectionHeader = css`
  > h2 {
    text-align: center;
    margin: 0;
  }
`;
const sectionBody = css`
  margin-top: 40px !important;
`;

export default function LegacySection({ heading, content, contentNode, customData, ctas }) {
  const flags = JSON.parse(customData || '{}').flags;
  return (
    <section css={[section]}>
      <header css={[sectionHeader, wrapLast, frame]}>
        <h2>{heading}</h2>
      </header>
      <div css={[sectionBody, wrapLast, frame]}>
        <MDXProvider
          components={{
            ul: (props) => <McolGrid as="ul" role="list" css={[mcolList]} {...props} />,
            li: ({ children, ...props }) => (
              <li css={[mcolListItem]} {...props}>
                <Icon icon="check" color="var(--primary-color)" />
                <span style={{ marginLeft: '12px' }}>{children}</span>
              </li>
            ),
          }}
        >
          <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </section>
  );
}
